var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              attrs: { flat: "", tile: "" },
            },
            [
              _c("Workflow", {
                attrs: {
                  width: "600",
                  height: "180",
                  steps: _vm.workflowSteps,
                  currentStep: _vm.workflowIndex,
                  labelWidth: 200,
                  lineWidth: 140,
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "8", lg: "10", md: "10", sm: "10", xs: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("TitleAndReturnComponent", {
                        attrs: {
                          title:
                            "Structure juridique > Super structure juridique",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [_c("WarningEditComponent")],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            xl: "6",
                            lg: "8",
                            md: "8",
                            sm: "10",
                            xs: "12",
                          },
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", active: _vm.loading },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "Détail de la super structure juridique"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    justify: "center",
                                    "align-self": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize font-weight-regular",
                                    },
                                    [_vm._v("nom digital")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "7", "align-self": "center" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Le nom digital",
                                      dense: "",
                                    },
                                    on: { blur: _vm.digitalNameFieldOut },
                                    model: {
                                      value: _vm.digitalName,
                                      callback: function ($$v) {
                                        _vm.digitalName = $$v
                                      },
                                      expression: "digitalName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    justify: "center",
                                    "align-self": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize font-weight-regular",
                                    },
                                    [_vm._v("nom complet")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "7", "align-self": "center" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Le nom complet",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.name,
                                      callback: function ($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    justify: "center",
                                    "align-self": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize font-weight-regular",
                                    },
                                    [_vm._v("structure juridique principale")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: { cols: "7", "align-self": "center" },
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      placeholder:
                                        "Sélectionner une structure juridique principale",
                                      items: _vm.availableMainLegalStructure,
                                      "item-text": "digitalName",
                                      "no-data-text":
                                        "aucune structure juridique",
                                      "return-object": "",
                                    },
                                    model: {
                                      value: _vm.mainLegalStructure,
                                      callback: function ($$v) {
                                        _vm.mainLegalStructure = $$v
                                      },
                                      expression: "mainLegalStructure",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-5 mt-1",
                              attrs: { "no-gutters": "", align: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    justify: "center",
                                    "align-self": "start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize font-weight-regular mt-2",
                                    },
                                    [
                                      _vm._v(
                                        " les structure juridique associées "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "7", "align-self": "start" } },
                                [
                                  _vm._l(
                                    _vm.associateLegalStructure,
                                    function (legal, i) {
                                      return _c(
                                        "div",
                                        { key: i },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-1",
                                              attrs: {
                                                "no-gutters": "",
                                                align: "center",
                                                justify: "space-between",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(legal.digitalName) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeAssociateLegalStructure(
                                                        legal
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-window-close"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            justify: "space-between",
                                          },
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items:
                                                _vm.availableLegalStructure,
                                              "item-text": "digitalName",
                                              "return-object": "",
                                              placeholder:
                                                "Associer une sructure juridique",
                                              hint: "Associer une structure juridique puis appuyer sur le bouton '+'",
                                              clearable: "",
                                              "no-data-text":
                                                "aucune structure juridique",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.addLegalStructure(
                                                  _vm.selectedLegalStructure
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.selectedLegalStructure,
                                              callback: function ($$v) {
                                                _vm.selectedLegalStructure = $$v
                                              },
                                              expression:
                                                "selectedLegalStructure",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                icon: "",
                                                disabled:
                                                  !_vm.selectedLegalStructure,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addLegalStructure(
                                                    _vm.selectedLegalStructure
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 px-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: !_vm.hasChanged,
                              },
                              on: { click: _vm.save },
                            },
                            [
                              _c("div", { staticClass: "capitalize" }, [
                                _vm._v(" enregistrer "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }