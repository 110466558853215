<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="8" lg="10" md="10" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Structure juridique > Super structure juridique" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de la super structure juridique</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom  -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom complet</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la structure juridique principale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">structure juridique principale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une structure juridique principale"
                    :items="availableMainLegalStructure"
                    item-text="digitalName"
                    no-data-text="aucune structure juridique"
                    v-model="mainLegalStructure"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les structure juridique associés -->
            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-2">
                    les structure juridique associées
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des structure juridique à associé -->
                  <div v-for="(legal, i) in associateLegalStructure" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ legal.digitalName }}
                      </div>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateLegalStructure(legal)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des structure juridique associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedLegalStructure"
                        :items="availableLegalStructure"
                        item-text="digitalName"
                        return-object
                        placeholder="Associer une sructure juridique"
                        hint="Associer une structure juridique puis appuyer sur le bouton '+'"
                        clearable
                        no-data-text="aucune structure juridique"
                        v-on:keyup.enter="addLegalStructure(selectedLegalStructure)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addLegalStructure(selectedLegalStructure)"
                        :disabled="!selectedLegalStructure"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              outlined
              class="ma-2 px-4 btn"
              color="primary"
              :disabled="!hasChanged"
              @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { SuperLegalStructureService } from "@/service/etablishment/super_legal_structure_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";
import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditSuperLegalStructure",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      serviceLegalStructure: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,
      
      /** le nom digital */
      digitalName: null,
      /** le nom */
      name: null,
      /** la structure juridique principale sélectionnée */
      mainLegalStructure: null,

      /** La liste des toutes les super structures juridiques */
      allSuperLegalStructures: [],
      /** La liste des structures juridiques principale sélectionnables */
      allAssociableLegalStructure: [],

      /** la liste des structure juridique associés */
      associateLegalStructure: [],

      /** la structure juridique sélectionnée pour l'ajout */
      selectedLegalStructure: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de la totalité des super structure juridiques
        this.allSuperLegalStructures = await this.service.getAll();
        // Récupère la super structure juridique
        this.source = this.allSuperLegalStructures.find((s) => s.id == this.entityId);
        // Récupère la liste des structure juridique
        let allLegalStructure = await this.serviceLegalStructure.getAll();

        // Récupération de la structure juridique principale
        if (this.source.mainLegalStructure) {
          this.mainLegalStructure = allLegalStructure.find((l) => l.id == this.source.mainLegalStructure);
        }

        // Parcours de toutes les super structure juridiques pour récupérer tous les ids de structure juridique déjà associés
        let idsAssociates = [];
        this.allSuperLegalStructures.forEach((s) => {
          if (s.id != this.source.id) {
            idsAssociates = idsAssociates.concat(s.legalStructures);
          }
        });
        // Récupération de toutes les structures juridqiues qui n'ont pas déjà été associés
        this.allAssociableLegalStructure = allLegalStructure.filter((l) => !idsAssociates.includes(l.id));

        // Les structure juridiques associés à la super structure juridique sans la structure juridique principale
        this.associateLegalStructure = this.allAssociableLegalStructure.filter((l) => 
          this.source.legalStructures.includes(l.id) 
          && this.source.mainLegalStructure != l.id);

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /**initialiser les données à partir de l'objet source */
    init() {
      this.digitalName = this.source.digitalName;
      this.name = this.source.name;
    },

    /** Suppression de la structure juridique de la liste des associés */
    removeAssociateLegalStructure(entity) {
      this.associateLegalStructure.splice(
        this.associateLegalStructure.findIndex((l) => l.id == entity.id),
        1
      );
    },

    /** Ajoute une structure juridique à la liste */
    addLegalStructure(entity) {
      if (!entity) return;

      this.associateLegalStructure.push(entity);
      this.selectedLegalStructure = null;
    },

    /** Sauvegarde d'une super structure juridique */
    async save() {

      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.digitalName = this.digitalName;
          entity.name = this.name;
          entity.mainLegalStructure = this.mainLegalStructure.id;

          entity.legalStructures = [];
          entity.legalStructures.push(this.mainLegalStructure.id);

          // Récupération de tous les ids des structure juridiques associés
          let ids = this.associateLegalStructure.map((l) => l.id);
          entity.legalStructures = entity.legalStructures.concat(ids);

          // création de la super structure juridique
          await this.service.update(entity);
          
          this.disableAlertQuit();
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la super structure juridique : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }    
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    /** retourne la liste des structure juridique non attribué à une super structure juridique */
    availableLegalStructure() {
      let availables = [];

      let ids = this.associateLegalStructure.map((l) => l.id);

      // si une structure juridique principale a été choisi, il faut le prendre en compte
      if (this.mainLegalStructure) {
        availables = this.allAssociableLegalStructure.filter((l) => !ids.includes(l.id) && l.id != this.mainLegalStructure.id);        
      } else {
        availables = this.allAssociableLegalStructure.filter((l) => !ids.includes(l.id));        
      }

      return availables;
    },

    /** Retourne la liste de toutes les structure juridiques disponibles pour une structure juridique principale */
    availableMainLegalStructure() {
      let availables = [];

      let ids = this.associateLegalStructure.map((l) => l.id);

      availables = this.allAssociableLegalStructure.filter((l) => !ids.includes(l.id));

      return availables;
    },

    completed() {
      if (!this.digitalName) return false;
      if (!this.name) return false;
      if (!this.mainLegalStructure) return false;

      return true;
    },

    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }   

      return changed;
    },
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(trimString(this.digitalName), this.source.digitalName)) {
        changed = true;
      }
      if (!areTheSame(this.name, this.source.name)) {
        changed = true;
      }
      if (this.mainLegalStructure.id != this.source.mainLegalStructure) {
        changed = true;
      }

      if (this.entitiesAssociateHasChanged) {
        changed = true;
      }

      return changed;
    },

    /** retourne true si des structure juridiques ont été ajoutés */
    entitiesAssociateHasChanged() {
      let changed = false;

      // A-t-on ajouté des établissements ?
      this.associateLegalStructure.forEach((aa) => {
        let found = this.source.legalStructures.find((ab) => aa.id == ab);
        if (!found) {
          changed = true;
        }
      });

      /** Pour éviter d'analyser la fonction inverse précédente, si on a déjà détecté du changement, on le retourne */
      if (changed) {
        return true;
      }

      // A-t-on supprimé des structures juridiques ?
      this.source.legalStructures.forEach((aa) => {
        if (aa != this.source.mainLegalStructure) {
          let found = this.associateLegalStructure.find((ab) => aa == ab.id);
          if (!found) {
            changed = true;
          }
        }
      });

      return changed;
    },    
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new SuperLegalStructureService(
      this.$api.getSuperLegalStructureApi()
    );
    this.serviceLegalStructure = new LegalStructureService(this.$api.getLegalStructuresApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>